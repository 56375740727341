<template>
  <div class="box">
    <div class="box-header">
      <h4>{{ pageTitle }}</h4>
    </div>
    <msg-form
      v-if="model"
      :submit-text="pageTitle"
      :model="model"
      :is-edit="false"
      @on-submit="createMsgCreate"
    >
    </msg-form>
    <placeholder-form v-else></placeholder-form>
  </div>
</template>

<script>
import MsgService from '@admin/services/MsgService'
import flatry from '@admin/utils/flatry'
import PlaceholderForm from '@core/components/Placeholder/PlaceholderForm'
import MsgForm from './components/_Form'

export default {
  name: '',
  data () {
    return {
      pageTitle: '创建',
      model: null
    }
  },

  components: {
    MsgForm,
    PlaceholderForm
  },

  async created () {
    let param = {
      send_type: ['1'],
      status: 1,
      selectArr: [],
      tempList: [],
      m_body: [{}]
    }
    const { data } = await flatry(MsgService.create())
    if (data) {
      param.selectArr = data.data.where
      param.tempList = data.data.tempList
      param.smsList = data.data.smsList
      this.model = param
    }
  },

  methods: {
    async createMsgCreate (model, success, callback) {
      const { data } = await flatry(MsgService.create(model))

      if (data) {
        this.$message.success(data.msg)
        this.$router.replace({
          path: '/msg-temp/update',
          query: { id: data.data }
        })

        success()
      }

      callback()
    }
  },

  watch: {}
}
</script>
<style lang='' scoped>
</style>
